.ceshi {
  font-size: 20px;
  color: red;
}
.appContainer {
  width: 100%;
  height: 100vh;
}
.container {
  height:100%;
  display:flex;
  flex-direction: column;
  overflow: hidden;
}
.imageContainer{
  width: 100%;
  height: 100vw;
}
.clickLayout:active{
  opacity: 0.5;
}
.nextBtn{
  flex:2;
  width:60vw;
  height:48px;
  border-radius: 6.5vw;
  background-color: #b81c22;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0.32px;
  font-family: PingFangSC-Regular;
  margin-right: 5vw;
}
.price{
  font-family: PingFangSC-Semibold;
  font-size:18px;
  color:#b81c22;
  /* margin-left: 5vw; */
}
.bottomBox{
  width:100%;
  height:81px;
  position: fixed;
  bottom:0;
  right:0;
  display:flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-top: 6px solid #F7F7F7;
}
.price{
  flex:1;
  font-family: PingFangSC-Semibold;
  font-size:18px;
  color:#b81c22;
  margin-left: 5vw;
}
.clickLayout:active{
  opacity: 0.5;
}
.h3d {
  background-color: black;
  height: 60vh;
  width: 100vw;
}
.header{
    width:100vw;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    z-index:200;
}
.boxContainer{
  width:100vw;
  height: 46vh;
  background-color: #FFF;
  overflow: hidden;
}
.tabParent{
  width:96vw;
  height:6vh;
  flex-direction:row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 19px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  display: flex;
}
.craftTabs{
  display: inline-block;
  margin-right: 45px;
  vertical-align: middle;
  line-height:6vh;
}
.tabItemSel{
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #b81c22;
    letter-spacing: 0.32px;
    text-align: left;
    height:100%;
    display:inline-block;
  }
  .tabItem{
    font-family: PingFangSC-Light;
    font-size: 16px;
    color: #000;
    letter-spacing: 0.32px;
    text-align: left;
    display: block;
  }
  .tabItem2{
    font-family: PingFangSC-Light;
    color: #000;
    letter-spacing: 0;
    display: block;
    text-align: left;
    font-size:8px;
    width:100%;
    overflow:hidden;
    line-height: 1;
    word-break: break-all;
    word-wrap:break-word;
    margin-top: 8px;
    margin-left: 5px;
  }
  .previewImg{
    position: relative;
    top: -60px;
    left: calc( 100vw - 60px );
    width:42px;
    height:42px;
    z-index: 100;
  }
  .tabChildren{
    width:calc(100vw - 20px);
    height: 44vh;
    display: flex;
    flex-direction:row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-left: 19px;
    margin-bottom: 81px;
    position: relative;
  }
  .tabChildren0{
    /* width:calc(100vw - 20px); */
    height: 36vh;
    /* height: 200px; */
    display: flex;
    flex-direction:column;
    overflow: auto;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: 19px;
    background-color: #fff;
    padding-bottom: 81px;
  }
  .fabricList {
    height: 36vh;
    width: 100vw;
  }
  .craftTabs2{
    margin: 0 5px;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    width: 80px;
    height: 130px;
    display:flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E7E7E7;
    padding-bottom:5px;
  }
  .craftTabs2Sel{
    margin: 0 5px;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    width: 80px;
    height: 130px;
    display:flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #b81c22;
    padding-bottom:5px;
  }

  .craftTabs3{
    margin-right: 10px;
    margin-top:16px;
    border-radius: 5px;
    padding-bottom: 10px;
    width:85px;
    height:125px;
    display:flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E7E7E7;
  }
  .craftTabs3Sel{
    margin-right: 10px;
    margin-top:16px;
    border-radius: 5px;
    padding-bottom: 10px;
    width:85px;
    height:125px;
    display:flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #b81c22;
  }
  .imageContainer2{
    border-radius: 5px ;
    width:82px;
    height:85px;
    overflow: hidden;
    position: relative;
  }
  .imageCon2Sel{
    border: 1px solid #b81c22;
    border-radius: 5px ;
    width:82px;
    height:85px;
    overflow: hidden;
    border: 1px solid transparent;
    position: relative;
  }
  .imageContainer3{
    /* border: 5px solid #fff; */
    border-radius: 5px ;
    width:47px;
    height:48px;
    overflow: hidden;
    /* box-shadow: 0px 0px 8px rgb(202, 202, 202); */
  }
  .imageCon3Sel{
    border-radius: 5px ;
    width:47px;
    height:48px;
    overflow: hidden;
    /* border: 5px solid transparent; */
    /* box-shadow: 0px 0px 0px rgb(202, 202, 202); */
  }
  .cornerImg{
    position: relative;
    width:16px;
    height:16px;
    left: 35%;
    bottom: 65%;
  }
  .cornerImg2{
    position: relative;
    width:16px;
    height:16px;
    left: 35%;
    bottom: 65%;
    opacity: 0;
  }
  .cornerImg3{
    position: absolute;
    width: 16px;
    height: 16px;
    right: 8px;
    top: 15px;
    border:1px solid #FFF;
    box-shadow: 0px 0px 2px gray;
    border-radius:9.5px;
  }
  .cornerImg3Sel {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 8px;
    top: 15px;
    border:1px solid #FFF;
    box-shadow: 0px 0px 2px gray;
    border-radius:9.5px;
    opacity: 0;
  }
  .fabricImage{
    width: 78px;
    height: 78px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .craftImage{
    width:82px;
    height:85px;
  }
  .canvas{
    background-image: linear-gradient(#f9f9fb, #ebeef3);
  }
  .loadContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sdkLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #E71519;
  }

  .sdkLoadingImg {
    width: 136px;
    height: 19px;
    object-fit: contain;
    text-align: center;
    margin-bottom: 8px;
  }

  .sdkLoadingTxt {
    
  }

  .tab-item-disable {
    text-decoration-line: line-through;
    color: #999
  }
  .disabled-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }