.selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 8px 16px;
}

.selector-input-container {
  height: 8.4vw;
  width: 33.6vw;
  background:rgba(247,247,247,1);
  padding: 4px;
  border-radius: 4.2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selector-input-img {
  width: 13px;
  height: 13px;
  margin: 0px 8px;
}

.selector-input {
  border: 0;
  background: transparent;
  flex: 1;
  width: calc(33.6vw - 33px);
  font-size:10px;
  font-family:PingFang-SC-Regular,PingFang-SC;
  font-weight:400;
  color:rgba(153,153,153,1);
  line-height:18px;
}

.selector-select {
  width: 25.6vw;
  height: 8.4vw;
  font-size: 30px;
  padding-left: 8px;
}

.selector-select-option {
  font-size: 8px;
}